var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("v-overlay", {
        attrs: {
          color: "light",
          value: _vm.loadingOverlayVisible && _vm.loadingVisible,
        },
      }),
      _c(
        "v-snackbar",
        {
          attrs: { color: "dark", timeout: -1 },
          model: {
            value: _vm.loadingVisible,
            callback: function ($$v) {
              _vm.loadingVisible = $$v
            },
            expression: "loadingVisible",
          },
        },
        [
          _c(
            "div",
            { staticClass: "d-flex flex-row" },
            [
              _c("span", { staticClass: "pa-2" }, [
                _vm._v(_vm._s(_vm.loadingMessage)),
              ]),
              _c("v-progress-circular", {
                staticClass: "pa-2 ml-auto",
                attrs: { indeterminate: "", color: "primary" },
              }),
            ],
            1
          ),
        ]
      ),
      _c(
        "v-snackbar",
        {
          attrs: { color: _vm.snackbarColor },
          model: {
            value: _vm.snackbarVisible,
            callback: function ($$v) {
              _vm.snackbarVisible = $$v
            },
            expression: "snackbarVisible",
          },
        },
        [
          _c(
            "div",
            { staticClass: "d-flex flex-row" },
            [
              _c("span", { staticClass: "pa-2" }, [
                _vm._v(_vm._s(_vm.snackbarMessage)),
              ]),
              _c(
                "v-btn",
                {
                  staticClass: "pa-2 ml-auto",
                  attrs: { text: "" },
                  on: { click: _vm.hideSnackbar },
                },
                [
                  _c("v-progress-circular", {
                    staticClass: "mr-2",
                    attrs: {
                      size: "23",
                      width: "2",
                      value: _vm.percCloseTimeout,
                      color: "white",
                    },
                  }),
                  _vm._v(" Fechar "),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }