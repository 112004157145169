var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.isMobile
    ? _c(
        "v-dialog",
        {
          staticClass: "mt-4",
          scopedSlots: _vm._u(
            [
              {
                key: "activator",
                fn: function ({ on, attrs }) {
                  return [
                    _c(
                      "v-card",
                      _vm._g(
                        _vm._b(
                          { staticClass: "card-button" },
                          "v-card",
                          attrs,
                          false
                        ),
                        on
                      ),
                      [
                        _c("v-card-title", { staticClass: "justify-center" }, [
                          _vm._v(" MENU "),
                        ]),
                        _c(
                          "v-card-text",
                          { staticClass: "text-center text-truncate" },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.cardTitle
                                    ? _vm.cardTitle
                                    : "Clique aqui para editar"
                                ) +
                                " "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ],
            null,
            false,
            574888616
          ),
        },
        [
          _c("menu-box-card", {
            attrs: { values: _vm.values, remove: _vm.remove },
          }),
        ],
        1
      )
    : _c("menu-box-card", { attrs: { values: _vm.values, remove: _vm.remove } })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }