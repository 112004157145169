export class Guid {
    public static validator = new RegExp(
        "^[a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12}$",
        "i"
    );

    private static EMPTY = "00000000-0000-0000-0000-000000000000";

    public static Empty = new Guid(this.EMPTY);

    public static isGuid(guid: unknown): guid is Guid | string {
        if (typeof guid !== "string" && guid instanceof Guid == false) {
            return false;
        }

        const value: string = (guid as string).toString();
        
        return !!guid && (guid instanceof Guid || Guid.validator.test(value));
    }

    public static create(): Guid {
        return new Guid(
            [
                Guid.gen(2),
                Guid.gen(1),
                Guid.gen(1),
                Guid.gen(1),
                Guid.gen(3),
            ].join("-")
        );
    }

    public static createEmpty(): Guid {
        return new Guid("emptyguid");
    }

    public static parse(guid: string): Guid {
        return new Guid(guid);
    }

    public static raw(): string {
        return [
            Guid.gen(2),
            Guid.gen(1),
            Guid.gen(1),
            Guid.gen(1),
            Guid.gen(3),
        ].join("-");
    }

    private static gen(count: number) {
        let out = "";
        for (let i = 0; i < count; i++) {
            out += (((1 + Math.random()) * 0x10000) | 0)
                .toString(16)
                .substring(1);
        }
        return out;
    }

    private value: string;

    private constructor(guid: Guid | string) {
        if (!guid) {
            throw new TypeError("Invalid argument; `value` has no value.");
        }

        this.value = Guid.EMPTY;

        if (Guid.isGuid(guid)) {
            this.value = guid.toString();
        }
    }

    public valueOf(): string {
        return this.value;
    }

    public equals(other: Guid | string): boolean {
        return Guid.isGuid(other) && this.value === other.toString();
    }

    public isEmpty(): boolean {
        return this.value === Guid.EMPTY;
    }

    public toString(): string {
        return this.value;
    }

    public toJSON(): string {
        return this.value;
    }

    public static compareTo(value1: Guid | string, value2: Guid | string): number {
        return value1.toString().toLowerCase().localeCompare(value2.toString().toLowerCase())
    }

    public static setDefaultReviver() {
        const originalParse = JSON.parse;

        JSON.parse = function (text, reviver = Guid.guidReviver, ...rest) {
            return originalParse(text, reviver, ...rest);
        };
    }

    public static guidReviver(_key: string, value: unknown) {
        if (typeof value === "string" && Guid.isGuid(value)) {
            return new Guid(value);
        }

        return value;
    }
}