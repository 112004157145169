import { render, staticRenderFns } from "./menu-box-card.vue?vue&type=template&id=dd262438"
import script from "./menu-box-card.vue?vue&type=script&lang=ts"
export * from "./menu-box-card.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\NewsSoftRepos\\WhatsApp\\src\\NewsSoft.WhatsApp.WebUI\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('dd262438')) {
      api.createRecord('dd262438', component.options)
    } else {
      api.reload('dd262438', component.options)
    }
    module.hot.accept("./menu-box-card.vue?vue&type=template&id=dd262438", function () {
      api.rerender('dd262438', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/features/registration/companies/menus/menu-box-card.vue"
export default component.exports