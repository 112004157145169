var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-app",
    [
      _c(
        "v-app-bar",
        {
          attrs: {
            app: "",
            color: "primary",
            dark: "",
            "clipped-left": "",
            "clipped-right": "",
          },
        },
        [
          _c("v-app-bar-nav-icon", {
            on: {
              click: function ($event) {
                $event.preventDefault()
                _vm.drawer = !_vm.drawer
              },
            },
          }),
          _c("v-spacer"),
          _vm.isChatRoute && !_vm.isConnected
            ? _c(
                "v-btn",
                {
                  attrs: {
                    dark: "",
                    color: "secondary",
                    loading: _vm.isReconnecting,
                    disabled: _vm.isReconnecting,
                  },
                  on: { click: _vm.connect },
                },
                [_vm._v(" Conectar ")]
              )
            : _vm._e(),
          _c(
            "v-menu",
            {
              attrs: {
                "offset-y": "",
                "nudge-width": 60,
                transition: "scale-transition",
              },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function ({ on, attrs }) {
                    return [
                      _c(
                        "v-btn",
                        _vm._g(
                          _vm._b(
                            { attrs: { icon: "", dark: "" } },
                            "v-btn",
                            attrs,
                            false
                          ),
                          on
                        ),
                        [_c("v-icon", [_vm._v("mdi-account-circle")])],
                        1
                      ),
                    ]
                  },
                },
              ]),
            },
            [
              _c(
                "v-card",
                [
                  _c(
                    "v-list",
                    [
                      !_vm.isAuthenticated
                        ? [
                            _c(
                              "v-list-item",
                              {
                                attrs: { to: _vm.AuthrorizationPaths.Register },
                              },
                              [
                                _c(
                                  "v-list-item-icon",
                                  [_c("v-icon", [_vm._v("mdi-account-plus")])],
                                  1
                                ),
                                _c("v-list-item-title", [
                                  _vm._v(" Registrar "),
                                ]),
                              ],
                              1
                            ),
                            _c(
                              "v-list-item",
                              { attrs: { to: _vm.AuthrorizationPaths.Login } },
                              [
                                _c(
                                  "v-list-item-icon",
                                  [_c("v-icon", [_vm._v("mdi-login")])],
                                  1
                                ),
                                _c("v-list-item-title", [_vm._v(" Entrar ")]),
                              ],
                              1
                            ),
                          ]
                        : [
                            _c(
                              "v-list-item",
                              {
                                attrs: { to: _vm.AuthrorizationPaths.Profile },
                              },
                              [
                                _c(
                                  "v-list-item-icon",
                                  [_c("v-icon", [_vm._v("mdi-account-cog")])],
                                  1
                                ),
                                _c("v-list-item-title", [
                                  _vm._v(" Gerenciar Conta "),
                                ]),
                              ],
                              1
                            ),
                            _c(
                              "v-list-item",
                              { attrs: { to: _vm.AuthrorizationPaths.LogOut } },
                              [
                                _c(
                                  "v-list-item-icon",
                                  [_c("v-icon", [_vm._v("mdi-exit-to-app")])],
                                  1
                                ),
                                _c("v-list-item-title", [_vm._v(" Sair ")]),
                              ],
                              1
                            ),
                          ],
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-navigation-drawer",
        {
          attrs: { app: "", clipped: "" },
          model: {
            value: _vm.drawer,
            callback: function ($$v) {
              _vm.drawer = $$v
            },
            expression: "drawer",
          },
        },
        [
          _c(
            "v-list",
            { attrs: { nav: "" } },
            [
              _c(
                "v-list-item-group",
                { attrs: { color: "primary" } },
                [
                  _c(
                    "v-list-item",
                    { attrs: { to: "/chat" } },
                    [
                      _c(
                        "v-list-item-icon",
                        [_c("v-icon", [_vm._v("mdi-forum")])],
                        1
                      ),
                      _c(
                        "v-list-item-content",
                        [_c("v-list-item-title", [_vm._v("Conversas")])],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-list-group",
                    {
                      attrs: {
                        "prepend-icon": "mdi-plus-box-multiple-outline",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function () {
                            return [
                              _c("v-list-item-title", [_vm._v("Cadastros")]),
                            ]
                          },
                          proxy: true,
                        },
                      ]),
                    },
                    [
                      _c(
                        "v-list-item",
                        { attrs: { to: "/users" } },
                        [
                          _c("v-list-item-title", [_vm._v("Usuários")]),
                          _c(
                            "v-list-item-icon",
                            [_c("v-icon", [_vm._v("mdi-account")])],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-list-item",
                        { attrs: { to: "/attendants" } },
                        [
                          _c("v-list-item-title", [_vm._v("Atendentes")]),
                          _c(
                            "v-list-item-icon",
                            [_c("v-icon", [_vm._v("mdi-face-agent")])],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-list-item",
                        { attrs: { to: "/managers" } },
                        [
                          _c("v-list-item-title", [_vm._v("Gerentes")]),
                          _c(
                            "v-list-item-icon",
                            [_c("v-icon", [_vm._v("mdi-account-tie")])],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-list-item",
                        { attrs: { to: "/schedule" } },
                        [
                          _c("v-list-item-title", [
                            _vm._v("Horários de Atendimento"),
                          ]),
                          _c(
                            "v-list-item-icon",
                            [_c("v-icon", [_vm._v("mdi-store-clock")])],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-list-item",
                        { attrs: { to: "/queues" } },
                        [
                          _c("v-list-item-title", [_vm._v("Filas")]),
                          _c(
                            "v-list-item-icon",
                            [_c("v-icon", [_vm._v("mdi-human-queue")])],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-list-item",
                        { attrs: { to: "/menus/insert" } },
                        [
                          _c("v-list-item-title", [_vm._v("Menus")]),
                          _c(
                            "v-list-item-icon",
                            [_c("v-icon", [_vm._v("mdi-message")])],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-list-item",
                        { attrs: { to: "/reports" } },
                        [
                          _c("v-list-item-title", [_vm._v("Relatos")]),
                          _c(
                            "v-list-item-icon",
                            [
                              _c("v-icon", [
                                _vm._v("mdi-comment-alert-outline"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-list-item",
                        { attrs: { to: "/message-templates" } },
                        [
                          _c("v-list-item-title", [
                            _vm._v("Modelos de Mensagens"),
                          ]),
                          _c(
                            "v-list-item-icon",
                            [
                              _c("v-icon", [
                                _vm._v("mdi-message-reply-text-outline"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-list-item",
                        { attrs: { to: "/default-messages" } },
                        [
                          _c("v-list-item-title", [
                            _vm._v("Mensagens Padrões"),
                          ]),
                          _c(
                            "v-list-item-icon",
                            [
                              _c("v-icon", [
                                _vm._v("mdi-message-reply-text-outline"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-list-item",
                        { attrs: { to: "/services" } },
                        [
                          _c("v-list-item-title", [_vm._v("Serviços de Api")]),
                          _c(
                            "v-list-item-icon",
                            [_c("v-icon", [_vm._v("mdi-api")])],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.userHasRoles(_vm.Roles.NewsSoft, _vm.Roles.Support)
                    ? _c(
                        "v-list-group",
                        {
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "prependIcon",
                                fn: function () {
                                  return [
                                    _c("v-icon", [
                                      _vm._v("mdi-application-cog-outline"),
                                    ]),
                                  ]
                                },
                                proxy: true,
                              },
                              {
                                key: "activator",
                                fn: function () {
                                  return [
                                    _c("v-list-item-title", [
                                      _vm._v("Gerenciamento"),
                                    ]),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            false,
                            1766655830
                          ),
                        },
                        [
                          _c(
                            "v-list-item",
                            { attrs: { to: _vm.CompanyListRoute } },
                            [
                              _c("v-list-item-title", [_vm._v("Empresas")]),
                              _c(
                                "v-list-item-icon",
                                [
                                  _c("v-icon", [
                                    _vm._v("mdi-office-building-outline"),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "v-list-item",
                    { attrs: { to: "/report" } },
                    [
                      _c(
                        "v-list-item-icon",
                        [_c("v-icon", [_vm._v("mdi-comment-alert-outline")])],
                        1
                      ),
                      _c(
                        "v-list-item-content",
                        [_c("v-list-item-title", [_vm._v("Relatos")])],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-main",
        { staticClass: "main" },
        [
          _c(
            "transition",
            { attrs: { name: _vm.transitionName } },
            [
              _c("router-view", {
                staticClass: "view",
                on: {
                  "close-drawers": function ($event) {
                    _vm.drawer = false
                  },
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", { attrs: { id: "drawer" } }),
      _c("action-controller", { ref: "action" }),
      _c("message-box", { ref: "messageBox" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }