var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-dialog",
    {
      key: _vm.calls,
      attrs: { "max-width": "25vw", persistent: "" },
      model: {
        value: _vm.open,
        callback: function ($$v) {
          _vm.open = $$v
        },
        expression: "open",
      },
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", [_vm._v(" " + _vm._s(_vm.title) + " ")]),
          _c(
            "v-card-text",
            [
              _vm._l(_vm.text, function (line, index) {
                return [
                  _c("span", { key: `line-${index}` }, [_vm._v(_vm._s(line))]),
                  index < _vm.text.length - 1
                    ? _c("br", { key: `br-${index}` })
                    : _vm._e(),
                ]
              }),
            ],
            2
          ),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _vm._l(_vm.buttons, function (button, index) {
                return _c(
                  "v-btn",
                  {
                    key: index,
                    attrs: { color: button.color, text: "" },
                    on: {
                      click: function ($event) {
                        return _vm.execCallback(button.value)
                      },
                    },
                  },
                  [
                    !String.isNullOrWhiteSpace(button.icon)
                      ? _c("v-icon", { attrs: { left: "" } }, [
                          _vm._v(" " + _vm._s(button.icon) + " "),
                        ])
                      : _vm._e(),
                    _vm._v(" " + _vm._s(button.text) + " "),
                  ],
                  1
                )
              }),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }