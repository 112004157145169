var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-card",
    {
      class: _vm.isMobile ? "mx-auto pt-3" : "mx-auto",
      attrs: { width: "400px", tile: "" },
    },
    [
      _c("v-card-title", [_vm._v(" OPÇÃO ")]),
      _c("v-card-text", [
        _c(
          "div",
          { staticClass: "d-block" },
          [
            _c("v-text-field", {
              attrs: {
                outlined: "",
                rules: _vm.rules.title,
                label: "Título da Opção",
              },
              model: {
                value: _vm.title,
                callback: function ($$v) {
                  _vm.title = $$v
                },
                expression: "title",
              },
            }),
            _c("v-textarea", {
              attrs: {
                outlined: "",
                "auto-grow": "",
                rows: "1",
                rules: _vm.rules.text,
                label: "Texto da Opção",
              },
              model: {
                value: _vm.text,
                callback: function ($$v) {
                  _vm.text = $$v
                },
                expression: "text",
              },
            }),
            _c("v-autocomplete", {
              attrs: {
                outlined: "",
                clearable: "",
                label: "Enviar para fila",
                items: _vm.queues,
                "item-text": "name",
                "item-value": "id",
                "return-object": "",
              },
              model: {
                value: _vm.selectedQueues,
                callback: function ($$v) {
                  _vm.selectedQueues = $$v
                },
                expression: "selectedQueues",
              },
            }),
            _c("v-textarea", {
              attrs: {
                outlined: "",
                "auto-grow": "",
                rows: "1",
                rules: _vm.rules.messageText,
                label: "Mensagem da Opção",
              },
              model: {
                value: _vm.messageText,
                callback: function ($$v) {
                  _vm.messageText = $$v
                },
                expression: "messageText",
              },
            }),
          ],
          1
        ),
      ]),
      _c(
        "v-card-actions",
        [
          _c("v-spacer"),
          _c(
            "v-btn",
            { attrs: { text: "", color: "error" }, on: { click: _vm.remove } },
            [_vm._v(" Remover "), _c("v-icon", [_vm._v("mdi-close")])],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }