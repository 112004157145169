import { Chat } from "@/typings/client.typings";
import { AxiosInstance, AxiosRequestConfig } from "axios";
import { BaseClient } from "./base-client";
import { Api } from "@/typings/api.typings";
import Result = Api.Result;
import { Guid } from "@/utils/guid";

export class ChatClient extends BaseClient {

    constructor(baseUrl?: string, instance?: AxiosInstance) {
        super(baseUrl, instance);
    }

    public async listConversation(query: IListConversationQuery): Promise<IConversationDto[]> {
        let url = this.baseUrl + "/api/chat/listconversation?";
        url += this.getParams(query);

        const options: AxiosRequestConfig = {
            method: "GET",
            url: url,
            headers: {
                "Accept": "application/json",
                "Authorization": `Bearer ${this.getAccessToken()}`
            }
        };

        let result = await this.sendRequest<IConversationDto[]>(options);

        if (result == null) {
            result = [];
        }

        return result;
    }

    public async listMessages(query: IListMessagesQuery): Promise<IMessageDto[]> {
        let url = this.baseUrl + "/api/chat/listmessages?";
        url += this.getParams(query);

        const options: AxiosRequestConfig = {
            method: "GET",
            url: url,
            headers: {
                "Accept": "application/json",
                "Authorization": `Bearer ${this.getAccessToken()}`
            }
        };

        let result = await this.sendRequest<IMessageDto[]>(options);

        if (result == null) {
            result = [];
        }

        return result;
    }

    public async listMessageTemplates(query: IListMessageTemplateQuery): Promise<IListMessageTemplateDto[]> {
        let url = this.baseUrl + "/api/chat/listMessageTemplates?";
        url += this.getParams(query);

        const options: AxiosRequestConfig = {
            method: "GET",
            url: url,
            headers: {
                "Accept": "application/json",
                "Authorization": `Bearer ${this.getAccessToken()}`
            }
        };

        let result = await this.sendRequest<IListMessageTemplateDto[]>(options);

        if (result == null) {
            result = [];
        }

        return result;
    }


    public async getMessageTemplate(query: IGetMessageTemplateQuery): Promise<IGetMessageTemplateDto> {
        let url = this.baseUrl + "/api/chat/getMessageTemplate?";
        url += this.getParams(query);

        const options: AxiosRequestConfig = {
            method: "GET",
            url: url,
            headers: {
                "Accept": "application/json",
                "Authorization": `Bearer ${this.getAccessToken()}`
            }
        };

        return await this.sendRequest<IGetMessageTemplateDto>(options);
    }


    public async seekMessage(query: ISeekMessageQuery): Promise<IMessageDto[]> {
        let url = this.baseUrl + "/api/chat/seekmessage?";
        url += this.getParams(query);

        const options: AxiosRequestConfig = {
            method: "GET",
            url: url,
            headers: {
                "Accept": "application/json",
                "Authorization": `Bearer ${this.getAccessToken()}`
            }
        };

        let result = await this.sendRequest<IMessageDto[]>(options);

        if (result == null) {
            result = [];
        }

        return result;
    }

    public async getReplyMessage(query: IGetReplyMessageQuery): Promise<IMessageDto> {
        let url = this.baseUrl + "/api/chat/getreplymessage?";
        url += this.getParams(query);

        const options: AxiosRequestConfig = {
            method: "GET",
            url: url,
            headers: {
                "Accept": "application/json",
                "Authorization": `Bearer ${this.getAccessToken()}`
            }
        };

        const result = await this.sendRequest<IMessageDto>(options);

        return result;
    }

    getConversationById(query: IGetConversationByIdQuery): Promise<IConversationDto> {
        let url = this.baseUrl + "/api/chat/getconversationbyid?";
        url += this.getParams(query);

        const options: AxiosRequestConfig = {
            method: "GET",
            url: url,
            headers: {
                "Accept": "application/json",
                "Authorization": `Bearer ${this.getAccessToken()}`
            }
        };

        return this.sendRequest(options);
    }

    getCustomerServiceById(query: IGetCustomerServiceByIdQuery): Promise<ICustomerServiceDto> {
        let url = this.baseUrl + "/api/chat/getcustomerservicebyid?";
        url += this.getParams(query);

        const options: AxiosRequestConfig = {
            method: "GET",
            url: url,
            headers: {
                "Accept": "application/json",
                "Authorization": `Bearer ${this.getAccessToken()}`
            }
        };

        return this.sendRequest(options);
    }

    getConversationStatus(query: IGetConversationStatusQuery): Promise<boolean> {
        let url = this.baseUrl + "/api/chat/getconversationstatus?";
        url += this.getParams(query);

        const options: AxiosRequestConfig = {
            method: "GET",
            url: url,
            headers: {
                "Accept": "application/json",
                "Authorization": `Bearer ${this.getAccessToken()}`
            }
        };

        return this.sendRequest(options);
    }

    sendMedia(command: ISendMediaCommand): Promise<string[]> {
        const url = this.baseUrl + "/api/chat/sendmedia";

        const formData = new FormData();
        for (let index = 0; index < command.files.length; index++) {
            const file = command.files[index];
            const sectionName = `file${index}`;
            formData.append(sectionName, file, `${file.name}; size=${file.size}`);
        }

        const options: AxiosRequestConfig = {
            method: "POST",
            url: url,
            headers: {
                "Contenty-Type": 'multipart/form-data',
                "Accept": "application/json",
                "Authorization": `Bearer ${this.getAccessToken()}`,
            },
            data: formData,
        };

        return this.sendRequest(options);
    }

    public async sendMessage(command: ISendMessageCommand): Promise<boolean> {
        const url = this.baseUrl + "/api/chat/sendmessage";

        const options: AxiosRequestConfig = {
            method: "POST",
            url: url,
            headers: {
                "Contenty-Type": 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${this.getAccessToken()}`,
            },
            data: command,
        };

        return this.sendRequest(options);
    }

    startCustomerService(command: IStartCustomerServiceCommand): Promise<void> {
        const url = this.baseUrl + "/api/chat/startcustomerservice";

        const options: AxiosRequestConfig = {
            method: "PUT",
            url: url,
            headers: {
                "Contenty-Type": "application/json",
                "Authorization": `Bearer ${this.getAccessToken()}`,
            },
            data: command,
        };

        return this.sendRequest(options);
    }

    endCustomerService(command: IEndCustomerServiceCommand): Promise<void> {
        const url = this.baseUrl + "/api/chat/endcustomerservice";

        const options: AxiosRequestConfig = {
            method: "PUT",
            url: url,
            headers: {
                "Contenty-Type": "application/json",
                "Authorization": `Bearer ${this.getAccessToken()}`,
            },
            data: command,
        };

        return this.sendRequest(options);
    }

    userTyping(command: IUserTypingCommand): Promise<void> {
        const url = this.baseUrl + "/api/chat/usertyping";

        const options: AxiosRequestConfig = {
            method: "POST",
            url: url,
            headers: {
                "Contenty-Type": "application/json",
                "Authorization": `Bearer ${this.getAccessToken()}`,
            },
            data: command,
        };

        return this.sendRequest(options);
    }

    selfDesignationToConversation(command: ISelfDesignationToConversationCommand): Promise<void> {
        const url = this.baseUrl + "/api/chat/selfdesignationtoconversation";

        const options: AxiosRequestConfig = {
            method: "PUT",
            url: url,
            headers: {
                "Contenty-Type": "application/json",
                "Authorization": `Bearer ${this.getAccessToken()}`,
            },
            data: command,
        };

        return this.sendRequest(options);
    }

    addAttendantService(command: IAddAttendantCommand): Promise<void | Result> {
        const url = this.baseUrl + "/api/chat/addattendanttoconversation";

        const options: AxiosRequestConfig = {
            method: "PUT",
            url: url,
            headers: {
                "Contenty-Type": "application/json",
                "Authorization": `Bearer ${this.getAccessToken()}`,
            },
            data: command,
        };

        return this.sendRequest(options);
    }

    listAvailableAttendantsForConversation(query: IListAvailableAttendantsForConversationQuery): Promise<IAttendantDto[]> {
        let url = this.baseUrl + "/api/chat/listavailableattendantsforconversation?";
        url += this.getParams(query);

        const options: AxiosRequestConfig = {
            method: "GET",
            url: url,
            headers: {
                "Accept": "application/json",
                "Authorization": `Bearer ${this.getAccessToken()}`
            }
        };

        return this.sendRequest(options);
    }

    changeAttendantService(command: IChangeAttendantCommand): Promise<void> {
        const url = this.baseUrl + "/api/chat/changeattendantofconversation";

        const options: AxiosRequestConfig = {
            method: "PUT",
            url: url,
            headers: {
                "Contenty-Type": "application/json",
                "Authorization": `Bearer ${this.getAccessToken()}`,
            },
            data: command,
        };

        return this.sendRequest(options);
    }

    removeAttendantService(command: IRemoveAttendantCommand): Promise<void> {
        const url = this.baseUrl + "/api/chat/removeattendantfromconversation";

        const options: AxiosRequestConfig = {
            method: "PUT",
            url: url,
            headers: {
                "Contenty-Type": "application/json",
                "Authorization": `Bearer ${this.getAccessToken()}`,
            },
            data: command,
        };

        return this.sendRequest(options);
    }

    listAttendantsFromConversation(query: IListAttendantsFromConversation): Promise<IAttendantDto[]> {
        let url = this.baseUrl + "/api/chat/ListAttendantsFromConversation?";
        url += this.getParams(query);

        const options: AxiosRequestConfig = {
            method: "GET",
            url: url,
            headers: {
                "Accept": "application/json",
                "Authorization": `Bearer ${this.getAccessToken()}`
            }
        };

        return this.sendRequest(options);
    }

    listAvailableAttendantsAndFromConversation(query: IListAvailableAttendantsAndFromConversation): Promise<IAttendantDto[]> {
        let url = this.baseUrl + "/api/chat/ListAvailableAttendantsAndFromConversation?";
        url += this.getParams(query);

        const options: AxiosRequestConfig = {
            method: "GET",
            url: url,
            headers: {
                "Accept": "application/json",
                "Authorization": `Bearer ${this.getAccessToken()}`
            }
        };

        return this.sendRequest(options);
    }

    sendToQueue(command: ISendToQueueCommand): Promise<void> {
        const url = this.baseUrl + "/api/chat/sendtoqueue";

        const options: AxiosRequestConfig = {
            method: "POST",
            url: url,
            headers: {
                "Contenty-Type": 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${this.getAccessToken()}`,
            },
            data: command,
        };

        return this.sendRequest(options);
    }

    listQueues(): Promise<IAttendantDto[]> {
        const url = this.baseUrl + "/api/chat/listqueues";

        const options: AxiosRequestConfig = {
            method: "GET",
            url: url,
            headers: {
                "Accept": "application/json",
                "Authorization": `Bearer ${this.getAccessToken()}`
            }
        };

        return this.sendRequest(options);
    }

    async listCustomerServicesOfConversation(query: IListCustomerServicesOfConversation): Promise<ICustomerServicesDto[]> {
        let url = this.baseUrl + "/api/chat/ListCustomerServicesOfConversation?";
        url += this.getParams(query);

        const options: AxiosRequestConfig = {
            method: "GET",
            url: url,
            headers: {
                "Accept": "application/json",
                "Authorization": `Bearer ${this.getAccessToken()}`
            },
        };

        const data = await this.sendRequest<ICustomerServicesDto[]>(options);

        return data.map(m => (<ICustomerServicesDto> {
            id: m.id,
            protocol: m.protocol,
            serviceStartedAt: new Date(m.serviceStartedAt.toString()),
            serviceStartedByMessageId: m.serviceStartedByMessageId
        }));
    }

    async editNicknameOfCustomer(command: IEditNicknameOfCustomerCommand): Promise<void> {
        const url = this.baseUrl + "/api/chat/EditNicknameOfCustomer";

        const options: AxiosRequestConfig = {
            method: "PUT",
            url: url,
            headers: {
                "Accept": "application/json",
                "Authorization": `Bearer ${this.getAccessToken()}`
            },
            data: command
        };

        return await this.sendRequest(options);
    }
}

export interface IServiceQueueDto {
    id: Guid,
    name: string;
}

export interface ISendToQueueCommand {
    conversationId: Guid,
    serviceQueueId: Guid,
    attendantId: Guid | null;
}

export interface IListConversationQuery extends IPagination {
    serviceQueueId: Guid;
    customerName?: string;
}

export interface IListMessagesQuery extends IPagination {
    conversationId: Guid,
    messenger: Chat.MessengerType;
}

export interface ISeekMessageQuery {
    conversationId: Guid,
    lastMessageId: Guid,
    messageId: Guid,
}

export interface IGetReplyMessageQuery {
    conversationId: Guid,
    messageId: string;
}

export interface IPagination {
    page: number,
    size: number;
}

export interface IGetConversationByIdQuery {
    conversationId: Guid;
}
export interface IListCustomerServicesOfConversation extends IPagination {
    conversationId: Guid,
    protocol: string | null,
}

export interface IGetCustomerServiceByIdQuery {
    customerServiceId: Guid;
}

export interface IGetConversationStatusQuery {
    conversationId: Guid;
}

export interface IConversationDto {
    id: Guid,
    messenger: Chat.MessengerType,
    customer: ICustomer,
    lastMessage: ILastMessage,
    lastReaction: Chat.IMessageReaction | null,
    isBeingServed: boolean,
    customerService: ICustomerServiceDto | null,
}

export interface ICustomerServiceDto {
    id: Guid,
    startedAt: Date,
    serviceQueueId: Guid | null,
    attendants: IAttendantDto[];
    protocol?: string;
    serviceStartedByMessageId?: Guid;
}

export interface ICustomer extends IPerson {
    customerId: Guid,
    isInternalUser: false;
}

export interface IPerson {
    id: Guid,
    name: string,
    isInternalUser: boolean;
}

export interface ILastMessage {
    id: Guid;
    message: Chat.IMessage;
    events: Chat.IMessageEvent[];
    reactions: Chat.IMessageReaction[];
}

export interface IMessageDto {
    id: Guid,
    message: Chat.IMessage,
    events: Chat.IMessageEvent[];
    reactions: Chat.IMessageReaction[];
}

export interface ISendMediaCommand {
    files: File[];
}

export interface ISendMessageCommand {
    conversationId: Guid,
    message: Chat.IMessage;
}

export interface IStartCustomerServiceCommand {
    conversationId: Guid;
}

export interface IEndCustomerServiceCommand {
    conversationId: Guid;
}

export interface IUserTypingCommand {
    userName: string,
    conversationId: Guid,
    isTyping: boolean;
}

export interface IAttendantDto {
    id: Guid,
    name: string;
}
export interface ICustomerServicesDto {
    id: Guid,
    serviceStartedAt: Date,
    serviceStartedByMessageId: Guid | null,
    protocol: string;
}

export interface ISelfDesignationToConversationCommand {
    conversationId: Guid;
}

export interface IAddAttendantCommand {
    usersId: Guid[],
    conversationId: Guid;
}

export interface IListAvailableAttendantsForConversationQuery {
    conversationId: Guid;
}

export interface IAttendantDto {
    id: Guid,
    name: string;
}

export interface IChangeAttendantCommand {
    userId: Guid,
    conversationId: Guid;
}

export interface IRemoveAttendantCommand {
    usersId: Guid[],
    conversationId: Guid;
}

export interface IListAttendantsFromConversation {
    conversationId: Guid;
}

export interface IListAvailableAttendantsAndFromConversation {
    conversationId: Guid;
}

export interface IEditNicknameOfCustomerCommand {
    customerId: Guid,
    nickname: string;
}

export interface IListMessageTemplateQuery {
    search: string;
    page: number;
    pageSize: number;
    conversationId: Guid;
}

export interface IListMessageTemplateDto {
    id: Guid;
    name: string;
    category: Api.Domain.Enums.MessageTemplateCategory;
    templateType: Api.Domain.Enums.MessageTemplateType;
    header?: string;
    content: string;
    footer?: string;
    tags: string[];
    messengerType?: Api.Domain.Enums.MessengerType;
}

export interface IGetMessageTemplateQuery {
    id: Guid;
}

export interface IGetMessageTemplateDto {
    header?: string;
    content: string;
    footer?: string;
}
