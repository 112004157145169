import type { Chat } from "@/typings/client.typings";
import type { Guid } from "@/utils/guid";
import type { Dictionary } from "./app";
import colors from 'vuetify/lib/util/colors';

export namespace Api {
    export type TimeSpan = string;

    export interface Result {
        success: boolean,
        errors: ResultError[],
    }

    export interface ResultError {
        id: number,
        message: string,
    }

    export interface IPagination {
        page: number,
        pageSize: number,
    }

    export namespace Domain {
        export namespace Enums {
            export enum DayOfWeek {
                Sunday = 0,
                Monday = 1,
                Tuesday = 2,
                Wednesday = 3,
                Thursday = 4,
                Friday = 5,
                Saturday = 6
            }
            export enum ApiServiceType {
                None = 0,
                GupShup = 1,
            }
            export enum MessengerType {
                None = 0,
                WhatsApp = 1,
                Telegram = 2,
                FacebookMessenger = 3,
                Instagram = 4
            }
            export const MessengerTypeDescription: Dictionary<MessengerType, string> = {} as unknown as Dictionary<MessengerType, string>;
            MessengerTypeDescription[MessengerType.None] = "Nenhum";
            MessengerTypeDescription[MessengerType.WhatsApp] = "WhatsApp";
            MessengerTypeDescription[MessengerType.Telegram] = "Telegram";
            MessengerTypeDescription[MessengerType.FacebookMessenger] = "Facebook Messenger";
            MessengerTypeDescription[MessengerType.Instagram] = "Instagram";

            export enum DefaultMessageType {
                When_Starting_Customer_Service = 0,
                When_Adding_New_Attendant = 1,
                When_Changing_Attendant = 2,
                When_Removing_Attendant = 3,
                When_Sending_To_Queue = 4,
            }

            export enum MessageTemplateType {
                Text = 0,
                Image = 1,
                Video = 2,
                Document = 3,
                Location = 4
            }

            export enum MessageTemplateStatus {
                None = 0,
                Created = 1,
                WaitingApprove = 2,
                Rejected = 3,
                Approved = 4,
                Deleted = 5,
                Disabled = 6,
                Paused = 7,
                Failed = 8,
                Error = 9,
            }

            export const MessageTemplateTypeDescription: Dictionary<MessageTemplateType, string> = {} as unknown as Dictionary<MessageTemplateType, string>;
            MessageTemplateTypeDescription[MessageTemplateType.Text] = "Texto";
            MessageTemplateTypeDescription[MessageTemplateType.Image] = "Imagem";
            MessageTemplateTypeDescription[MessageTemplateType.Video] = "Video";
            MessageTemplateTypeDescription[MessageTemplateType.Document] = "Documento";
            MessageTemplateTypeDescription[MessageTemplateType.Location] = "Localização";

            export enum UserLevel {
                Bot = -2,
                WaitingCompleteRegistration = -1,
                None = 0,
                Attendant = 1,
                Manager = 2,
                Owner = 3,
            }

            export enum MessageTemplateButtonType {
                Text = 0,
                PhoneNumber = 1,
                Url = 2,
                CopyCode = 3,
                GoToApp = 4,
            }

            export enum MessageTemplateCategory {
                None = 0,
                Marketing = 1,
                Utility = 2,
                Authentication = 3,
            }

            export interface MessageTemplateButtonTypeItem<T> {
                type: T,
                name: string,
                icon?: string,
                visibleFor: MessageTemplateCategory[];
            }

            export const MessageTemplateButtonTypes: Dictionary<MessageTemplateButtonType, MessageTemplateButtonTypeItem<MessageTemplateButtonType>> = {} as unknown as Dictionary<MessageTemplateButtonType, MessageTemplateButtonTypeItem<MessageTemplateButtonType>>;
            MessageTemplateButtonTypes[MessageTemplateButtonType.Text] = {
                type: MessageTemplateButtonType.Text,
                name: 'Resposta Rápida',
                visibleFor: [MessageTemplateCategory.Marketing, MessageTemplateCategory.Utility],
                icon: 'mdi-reply'
            };
            MessageTemplateButtonTypes[MessageTemplateButtonType.PhoneNumber] = {
                type: MessageTemplateButtonType.PhoneNumber,
                name: 'Telefone',
                visibleFor: [MessageTemplateCategory.Marketing, MessageTemplateCategory.Utility],
                icon: 'mdi-phone'
            };
            MessageTemplateButtonTypes[MessageTemplateButtonType.Url] = {
                type: MessageTemplateButtonType.Url,
                name: 'Url',
                visibleFor: [MessageTemplateCategory.Marketing, MessageTemplateCategory.Utility],
                icon: 'mdi-link-variant'
            };
            MessageTemplateButtonTypes[MessageTemplateButtonType.CopyCode] = {
                type: MessageTemplateButtonType.CopyCode,
                name: 'Copiar Código',
                visibleFor: [MessageTemplateCategory.Marketing, MessageTemplateCategory.Authentication],
                icon: 'mdi-content-copy'
            };
            MessageTemplateButtonTypes[MessageTemplateButtonType.GoToApp] = {
                type: MessageTemplateButtonType.GoToApp,
                name: 'Ir para o Aplicativo',
                visibleFor: [MessageTemplateCategory.Authentication],
                icon: 'mdi-arrow-bottom-right'
            };

            export const MessageTemplateCategoryDescription: Dictionary<MessageTemplateCategory, string> = {} as unknown as Dictionary<MessageTemplateCategory, string>;
            MessageTemplateCategoryDescription[MessageTemplateCategory.None] = "Nenhum";
            MessageTemplateCategoryDescription[MessageTemplateCategory.Marketing] = "Marketing";
            MessageTemplateCategoryDescription[MessageTemplateCategory.Utility] = "Utilitário";
            MessageTemplateCategoryDescription[MessageTemplateCategory.Authentication] = "Autenticação";

            interface TypeItem<T> {
                type: T;
                name: string;
                color?: string;
            }

            export enum WorkPriority {
                Lowest = 0,
                Low = 1,
                Medium = 2,
                High = 3,
                Highest = 4,
            }

            export const WorkPriorities: Dictionary<WorkPriority, TypeItem<WorkPriority>> = {
                [WorkPriority.Lowest]: { type: WorkPriority.Lowest, name: "Mais baixo" },
                [WorkPriority.Low]: { type: WorkPriority.Low, name: "Baixo" },
                [WorkPriority.Medium]: { type: WorkPriority.Medium, name: "Médio" },
                [WorkPriority.High]: { type: WorkPriority.High, name: "Alto" },
                [WorkPriority.Highest]: { type: WorkPriority.Highest, name: "Mais alto" },
            };

            export enum WorkStatus {
                Created = 1,
                Enqueued = 2,
                Running = 3,
                Completed = 4,
                Error = 5,
                Canceled = 6
            }

            export const WorkStatuses: Dictionary<WorkStatus, TypeItem<WorkStatus>> = {
                [WorkStatus.Created]: { type: WorkStatus.Created, name: "Criado", color: colors.green.lighten5 },
                [WorkStatus.Enqueued]: { type: WorkStatus.Enqueued, name: "Na fila", color: colors.green.lighten4 },
                [WorkStatus.Running]: { type: WorkStatus.Running, name: "Em execução", color: colors.green.lighten3 },
                [WorkStatus.Completed]: { type: WorkStatus.Completed, name: "Completado", color: colors.green.lighten2 },
                [WorkStatus.Error]: { type: WorkStatus.Error, name: "Erro", color: colors.red.lighten3 },
                [WorkStatus.Canceled]: { type: WorkStatus.Canceled, name: "Cancelada", color: colors.yellow.lighten1 },
            };
        }
    }

    export namespace Companies {
        export namespace Commands {

            export namespace AddUser {
                export interface IAddUserCommand {
                    email: string;
                }
            }

            export namespace RemoveUser {
                export interface IRemoveUserCommand {
                    userId: Guid;
                }
            }
            export namespace AddApiService {
                export interface IAddApiServiceCommand {
                    companyId: Guid;
                    name: string;
                    uri: string;
                    sourceNumber: string;
                    type: Domain.Enums.ApiServiceType;
                    messenger: Domain.Enums.MessengerType;
                    isSandbox?: boolean;
                    canAutoRegisterUser?: boolean;
                    token?: string;
                }
            }

            export namespace RemoveApiService {
                export interface IRemoveApiServiceCommand {
                    apiServiceId: Guid;
                }
            }
            export namespace UpdateApiService {
                export interface IUpdateApiServiceCommand {
                    apiServiceId: Guid;
                    uri: string;
                    sourceNumber: string;
                    isSandbox?: boolean;
                    canAutoRegisterUser?: boolean;
                }
            }

            export namespace AddCompany {
                export interface IAddCompanyCommand {
                    name: string;
                    ownerEmail: string;
                }
            }

            export namespace RemoveCompany {
                export interface IRemoveCompanyCommand {
                    companyId: Guid;
                }
            }

            export namespace UpdateCompany {
                export interface IUpdateCompanyCommand {
                    companyId: Guid;
                    name: string;
                }
            }

            export namespace SaveAttendants {
                export interface ISaveAttendantsCommand {
                    usersToAdd?: Guid[],
                    usersToRemove?: Guid[],
                }
            }

            export namespace SaveManagers {
                export interface ISaveManagersCommand {
                    usersToAdd?: Guid[],
                    usersToRemove?: Guid[],
                }
            }

            export namespace SaveOfficeHours {
                export interface ISaveOfficeHoursCommand {
                    officeHours: OfficeHourDto[];
                }

                export interface OfficeHourDto {
                    dayOfWeek: Domain.Enums.DayOfWeek,
                    workTimes: WorkTimeDto[];
                }

                export interface WorkTimeDto {
                    start: TimeSpan,
                    end: TimeSpan;
                }
            }

            export namespace AddQueue {
                export interface IAddQueueCommand {
                    name: string,
                    users?: Guid[];
                }
            }

            export namespace RemoveQueue {
                export interface IRemoveQueueCommand {
                    serviceQueueId: Guid;
                }
            }

            export namespace UpdateQueue {
                export interface IUpdateQueueCommand {
                    serviceQueueId: Guid;
                    name: string,
                    users: Guid[],
                }
            }

            export namespace AddMenu {
                export interface IAddMenuCommand {
                    menuDTO: IMenuDTO;
                    // title: string,
                    // text: string,
                    // menuMessageType: Chat.MenuMessageType
                    // optionsToAdd?: IMenuOptionDTO[]
                }

                export interface IMenuDTO {
                    menuId?: Guid,
                    id?: number,
                    title: string,
                    text: string,
                    menuMessageType: Chat.MenuMessageType;
                    optionsToAdd?: IMenuOptionDTO[];
                }
            }

            export namespace RemoveMenu {
                export interface IRemoveMenuCommand {
                    id: Guid;
                }
            }

            export namespace UpdateMenu {
                export interface IUpdateMenuCommand {
                    menuId: Guid,
                    menuDTO: IMenuUpdateDTO;
                    // title?: string,
                    // text?: string,
                    // menuMessageType: Chat.MenuMessageType,
                    // optionsToAdd?: IMenuOptionDTO[],
                }

                export interface IMenuUpdateDTO {
                    menuId?: Guid,
                    id?: number,
                    title: string,
                    text: string,
                    menuMessageType: Chat.MenuMessageType;
                    optionsToAdd?: IMenuOptionDTO[],
                    optionsToRemove?: string[];
                }
            }

            export interface IMenuOptionDTO {
                menuOptionId?: Guid,
                title: string,
                text?: string,
                serviceQueueId?: string,
                messageText?: string,
                goToMenu?: AddMenu.IMenuDTO;
            }

            export interface MenuItem {
                menuId?: Guid,
                id: number;
                parentId: number;
                title: string;
                text: string;
                menuMessageType: number;
                optionsToAdd?: MenuOptionItem[];
            }

            export interface MenuOptionItem {
                menuOptionId?: Guid,
                id: number;
                parentId: number;
                title: string;
                text: string;
                serviceQueueId: Guid;
                messageText: string;
                goToMenu?: MenuItem;
            }

            export namespace AddDefaultMessage {
                export interface IAddDefaultMessageCommand {
                    defaultMessageType: number,
                    message: string;
                }
            }

            export namespace RemoveDefaultMessage {
                export interface IRemoveDefaultMessageCommand {
                    defaultMessageId: Guid;
                }
            }

            export namespace UpdagteDefaultMessage {
                export interface IUpdateDefaultMessageCommand {
                    defaultMessageId: Guid,
                    defaultMessageType: number,
                    message: string;
                }
            }
        }

        export namespace Queries {
            export namespace ListAttendants {

                export enum FilterBy {
                    None = 0,
                    Users = 1,
                    Attendants = 2,
                }
                export interface IListAttendantsQuery extends IPagination {
                    search?: string,
                    filterBy?: FilterBy;
                }

                export interface IAttendantDto {
                    id: Guid,
                    name: string,
                    isAttendant: boolean;
                }
            }

            export namespace ListManagers {

                export enum FilterBy {
                    None = 0,
                    Users = 1,
                    Managers = 2,
                }
                export interface IListManagersQuery extends IPagination {
                    search?: string,
                    filterBy?: FilterBy;
                }

                export interface IManagerDto {
                    id: Guid,
                    name: string,
                    isManager: boolean;
                }
            }

            export namespace ListOfficeHours {
                export interface OfficeHourDto {
                    dayOfWeek: Domain.Enums.DayOfWeek,
                    workTimes: WorkTimeDto[];
                }

                export interface WorkTimeDto {
                    start: TimeSpan,
                    end: TimeSpan;
                }
            }

            export namespace ListUsers {
                export interface IListUsersQuery extends IPagination {
                    search?: string | null;
                }

                export interface IUserDto {
                    id: Guid,
                    email: string;
                    name: string;
                    level: Domain.Enums.UserLevel;
                }
            }
            export namespace GetApiServiceById {
                export interface IGetApiServiceByIdQuery {
                    id: Guid;
                }
                export interface IApiServiceDto {
                    id: Guid;
                    companyId: Guid;
                    name: string;
                    uri: string;
                    sourceNumber: string;
                    type: Domain.Enums.ApiServiceType;
                    messenger: Domain.Enums.MessengerType;
                    isSandbox: boolean;
                    hasToken: boolean;
                    canAutoRegisterUser: boolean;
                }
            }
            export namespace GetCompanyById {
                export interface IGetCompanyByIdQuery {
                    id: Guid;
                }
            }
            export namespace ListApiService {
                export interface IListApiServiceQuery extends IPagination {
                    companyId: Guid;
                    search?: string | null;
                }

                export interface IServiceDto {
                    id: Guid;
                    name: string;
                    uri: string;
                    sourceNumber: string;
                    type: Domain.Enums.ApiServiceType;
                    messenger: Domain.Enums.MessengerType;
                    isSandbox?: boolean;
                    hasToken?: boolean;
                    canAutoRegisterUser?: boolean;
                }
            }
            export namespace ListCompanies {
                export interface IListCompaniesQuery extends IPagination {
                    search?: string | null;
                }

                export interface ICompanyDto {
                    id: Guid;
                    name: string;
                }
            }

            export namespace ListQueues {
                export interface IListQueuesQuery extends IPagination {
                    search?: string | null;
                }

                export interface IListUsersQueueQuery {
                    id: Guid;
                }

                export interface IQueueDto {
                    id: Guid,
                    name: string;
                }

                export interface IQueueUserDto {
                    id: Guid,
                    displayName: string;
                }
            }

            export namespace ListQueuesOfUser {
                export interface IListQueuesOfUser {

                }

                export interface IQueueUserDto {
                    id: Guid,
                    name: string;
                }
            }

            export namespace ListMenus {
                export interface IListMenusQuery {
                }

                export interface IListOptionsMenuQuery {
                }

                export interface IMenuDto {
                    id: Guid,
                    title: string,
                    text: string,
                    menuMessageType: number,
                    options: IMenuOptionDto[];
                }

                export interface IMenuOptionDto {
                    id: Guid,
                    title: string,
                    text: string,
                    serviceQueueId: Guid;
                    messageText: string;
                    goToMenu?: IMenuDto;
                }

                export enum MenuMessageType {
                    Button = 0,
                    List = 1
                }

                export interface IMenuNodeDto {
                    id: Guid;
                    parentId: number;
                    nodeComponent: string;
                    data: {
                        title: string;
                        text: string;
                        menuMessageType?: number;
                        serviceQueueId?: ListQueues.IQueueDto,
                        messageText?: string,
                        removeButton: boolean;
                    };
                }
            }

            export namespace ListDefaultMessages {
                export interface IListDefaultMessagesQuery extends IPagination {
                    search?: string | null;
                }

                export interface IDefaultMessagesDto {
                    id: Guid,
                    defaultMessageType: Domain.Enums.DefaultMessageType,
                    message: string;
                }

                export interface IGetDefaultMessageById {
                    id: Guid;
                }
            }
        }
    }

    export namespace Attendants {
        export namespace Queries {
            export interface IAttendantDto {
                id: Guid,
                name: string;
            }
        }
    }

    export namespace Reports {

        export enum ValidationRuleType {
            Required = 0,
            MinLength = 1,
            MaxLength = 2,
            GreaterThan = 3,
            GreaterThanOrEqualTo = 4,
            LessThan = 5,
            LessThanOrEqualTo = 6
        }

        export enum FieldType {
            Text = 0,
            Number = 1,
            Date = 2,
            DateTime = 3,
            Time = 4
        }

        export namespace AddReport {
            export interface IAddReportCommand {
                title: string,
                questions: IReportQuestionDto[];
            }

            export interface IReportQuestionDto {
                title: string,
                type: FieldType,
                validationRules: IValidationRulesDto[];
            }

            export interface IValidationRulesDto {
                type: ValidationRuleType,
                value: string;
            }
        }

        export namespace EditReport {
            export interface IEditReportQuery {
                id: Guid;
            }

            export interface IEditReportCommand {
                report: IReportDto;
            }

            export interface IReportDto {
                id: Guid,
                title: string,
                questions?: IReportQuestionDto[],
                questionsToRemove?: Guid[];
            }

            export interface IReportQuestionDto {
                id?: Guid,
                title: string,
                type: FieldType,
                validationRules: IValidationRulesDto[];
            }

            export interface IValidationRulesDto {
                id?: Guid,
                type: ValidationRuleType,
                value: string;
            }
        }

        export namespace AddReportEntry {
            export namespace ListReports {
                export interface IReportDto {
                    id: Guid,
                    title: string,
                }
            }

            export interface IListCustomerServicesOfConversationQuery {
                conversationId: Guid;
            }

            export interface ICustomerServiceDto {
                id: Guid,
                serviceStartedAt: Date;
            }

            export interface IGetReportQuery {
                id: Guid;
            }

            export interface IAddReportEntryCommand {
                reportId: Guid,
                conversationId: Guid,
                data: ReplyDto[],
                customerServicesId: Guid[];
            }

            export interface ReplyDto {
                questionId: Guid,
                value: string;
            }

            export interface IReportDto {
                id: Guid,
                title: string,
                questions?: IReportQuestionDto[],
                questionsToRemove?: Guid[];
            }

            export interface IReportQuestionDto {
                id?: Guid,
                title: string,
                type: FieldType,
                validationRules: IValidationRulesDto[];
            }

            export interface IValidationRulesDto {
                id?: Guid,
                type: ValidationRuleType,
                value: string;
            }
        }

        export namespace ListEntriesByReport {
            export interface IListEntriesByReportQuery {
                reportId: Guid,
                initialDate: Date,
                finalDate: Date;
            }
        }

        export namespace Queries {

            export namespace GetReportById {
                export interface IGetReportByIdQuery {
                    id: Guid;
                }

                export interface IReportDto {
                    id: Guid,
                    title: string,
                    questions: QuestionDto;
                }

                export interface QuestionDto {
                    id: Guid,
                    title: string,
                    type: FieldType,
                    index: number,
                    validationRules: ValidationRulesDto[],
                }

                export interface ValidationRulesDto {
                    id: Guid,
                    type: ValidationRuleType,
                    value: string;
                }
            }

            export interface ListReports {
                id: Guid,
                title: string;
            }

            export interface IListReportsQuery extends IPagination {
                search?: string | null;
            }

            export interface IReportDto {
                id: Guid,
                title: string;
            }

            export interface IGetReportByIdQuery {
                id: Guid;
            }

            export interface ReportDto {
                id: Guid,
                title: string,
                questions: ReportQuestionDto[],
                customerServiceIds: Guid[];
            }

            export interface ReportQuestionDto {
                id: Guid,
                title: string,
                type: string,
                index: number,
                validationRules: ValidationRulesDto[],
                reply: string;
            }

            export interface ValidationRulesDto {
                id?: Guid,
                type: string,
                value: string;
            }

            export interface ReportEntry {
                reportId: Guid,
                customerId: Guid,
                data: DataDto[];
            }

            export interface DataDto {
                questionId: Guid,
                reply: string;
            }

            export interface IListCustomerServicesOfConversationQuery {
                conversationId: string;
            }

            export interface CustomerServicesDto {
                id: string,
                serviceStartedAt: string;
            }
        }

        export namespace Commands {
            export interface IRemoveReportCommand {
                reportId: Guid;
            }


        }
    }

    export namespace MessageTemplate {
        export namespace Commands {
            export namespace AddMessageTemplate {
                export namespace Normal {
                    export interface IAddNormalMessageTemplateCommand {
                        name: string;
                        templateType: Api.Domain.Enums.MessageTemplateType;
                        header?: string;
                        content: string;
                        footer?: string;
                        tags?: string[];
                    }
                }

                export namespace WhatsApp {
                    export interface IAddWhatsAppMessageTemplateCommand {
                        name: string;
                        content: string;
                        exampleContent: string;
                        apiServiceOptionsId: Guid;
                        category: Api.Domain.Enums.MessageTemplateCategory;
                        templateType: Api.Domain.Enums.MessageTemplateType;
                        header?: string;
                        exampleHeader?: string;
                        footer?: string;
                        tags?: string[];
                        buttons?: MessageTemplateButtonDto[];
                    }

                    export interface MessageTemplateButtonDto {
                        type: Domain.Enums.MessageTemplateButtonType;
                        title: string;
                        value?: string,
                    }
                }
            }

            export namespace RemoveMessageTemplate {
                export interface IRemoveMessageTemplateCommand {
                    id: Guid;
                }
            }

            export namespace UpdateMessageTemplate {
                export namespace Normal {
                    export interface IUpdateNormalMessageTemplateCommand {
                        id: Guid;
                        name: string;
                        content: string;
                        header?: string;
                        footer?: string;
                        tags?: string[];
                    }
                }

                export namespace WhatsApp {
                    export interface IUpdateWhatsAppMessageTemplateCommand {
                        id: Guid;
                        name: string;
                        content: string;
                        exampleContent: string;
                        header?: string;
                        exampleHeader?: string;
                        footer?: string;
                        tags?: string[];
                        buttons?: MessageTemplateButtonDto[];
                    }

                    export interface MessageTemplateButtonDto {
                        id: Guid | null,
                        type: Domain.Enums.MessageTemplateButtonType;
                        title: string;
                        value?: string,
                    }
                }
            }

            export interface MessageTemplateTagDto {
                name: string;
            }

        }

        export namespace Queries {
            export namespace GetMessageTemplate {
                export interface GetMessageTemplateQuery {
                    id: Guid;
                }
                export interface IMessageTemplateDto {
                    id: Guid,
                    name: string,
                    templateType: Domain.Enums.MessageTemplateType;
                    category: Domain.Enums.MessageTemplateCategory,
                    apiServiceOptionsId?: Guid,
                    header: string,
                    content: string,
                    footer?: string,
                    tags?: string[];
                    buttons?: MessageTemplateButtonDto[];
                    exampleContent?: string;
                    exampleHeader?: string;
                }

                export interface MessageTemplateButtonDto {
                    id: Guid,
                    type: Domain.Enums.MessageTemplateButtonType;
                    title: string;
                    value?: string,
                }
            }

            export namespace ListMessagesTemplatesEvents {
                export interface IListMessageTemplateEventQuery {
                    id: Guid;
                }

                export interface IMessageTemplateEventDto {
                    createdAt: string,
                    title: string,
                    description: string,
                }
            }

            export namespace ListMessageTemplates {
                export interface IListMessageTemplatesQuery extends IPagination {
                    search?: string | null;
                }

                export interface IMessageTemplateDto {
                    id: Guid;
                    name: string;
                    category: Domain.Enums.MessageTemplateCategory;
                    templateType: Domain.Enums.MessageTemplateType;
                    header?: string;
                    content: string;
                    footer?: string;
                    tags: string[];
                    messengerType: Domain.Enums.MessengerType;
                    status: Domain.Enums.MessageTemplateStatus;
                    workNotification?: IWorkNotificationDto;
                }

                export interface IWorkNotificationDto {
                    id: Guid;
                    name: string;
                    priority: Domain.Enums.WorkPriority;
                    createdAt: Date;
                    status: Domain.Enums.WorkStatus;
                    histories?: IWorkNotificationHistoryDto[];
                }

                export interface IWorkNotificationHistoryDto {
                    createdAt: Date;
                    status: Domain.Enums.WorkStatus;
                    error?: string;
                }
            }

            export namespace ListApiServiceOptions {
                export interface ApiServiceOptionDto {
                    id: Guid;
                    messengerType: Api.Domain.Enums.MessengerType;
                    sourceNumber: string;
                }
            }
        }
    }

    export namespace Notifications {
        export namespace Commands {
            export namespace RequeueNotification {
                export interface IRequeueNotificationCommand {
                    workNotificationId: Guid;
                    area: 'MessageTemplate_Create';
                }
            }
        }
    }

    export namespace Registrations {
        export namespace ApiServiceOptions {
            export namespace Queries {
                export namespace ListApiServicesOptions {
                    export interface IListApiServicesOptionsQuery extends IPagination { }

                    export interface IApiServiceOptionsDto {
                        id: Guid;
                        sourceNumber: string;
                        messenger: Domain.Enums.MessengerType;
                        disable: boolean;
                    }
                }

                export namespace ListApiServiceOptionsEvents {
                    export interface IListApiServiceOptionsEventsQuery {
                        apiServiceOptionsId: Guid;
                    }

                    export interface IApiServiceOptionsEventDto {
                        createdAt: string;
                        title: string;
                        description: string;
                    }
                }
            }
        }
    }
}
