var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-card",
    {
      class: _vm.isMobile ? "mx-auto pt-3" : "mx-auto",
      attrs: { color: "white", width: "400px", tile: "" },
    },
    [
      _c("v-card-title", [_vm._v(" MENU ")]),
      _c("v-card-text", [
        _c(
          "div",
          { staticClass: "d-block" },
          [
            _c("v-text-field", {
              attrs: {
                outlined: "",
                rules: _vm.rules.title,
                label: "Título do Menu",
              },
              model: {
                value: _vm.title,
                callback: function ($$v) {
                  _vm.title = $$v
                },
                expression: "title",
              },
            }),
            _c("v-textarea", {
              attrs: {
                outlined: "",
                "auto-grow": "",
                rows: "1",
                rules: _vm.rules.text,
                label: "Texto do Menu",
              },
              model: {
                value: _vm.text,
                callback: function ($$v) {
                  _vm.text = $$v
                },
                expression: "text",
              },
            }),
            _c(
              "v-radio-group",
              {
                attrs: { row: "" },
                model: {
                  value: _vm.menuMessageType,
                  callback: function ($$v) {
                    _vm.menuMessageType = $$v
                  },
                  expression: "menuMessageType",
                },
              },
              [
                _c("v-radio", {
                  attrs: { label: "Botão", value: _vm.MenuMessageType.Button },
                }),
                _c("v-radio", {
                  attrs: { label: "Lista", value: _vm.MenuMessageType.List },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c(
        "v-card-actions",
        [
          _c("v-spacer"),
          _vm.removeButton
            ? _c(
                "v-btn",
                {
                  attrs: { text: "", color: "error" },
                  on: { click: _vm.remove },
                },
                [_vm._v("Remover"), _c("v-icon", [_vm._v("mdi-close")])],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }